<!--  -->
<template>
  <div class="container">
    <div class="List-title">
        <span :style="{'color':open=='01'?'#00a5ff':'#222'}" @click="changeList('01')" >景点介绍</span>
        <span :style="{'color':open=='02'?'#00a5ff':'#222'}" @click="changeList('02')" >景点荣誉</span>
        <span :style="{'color':open=='03'?'#00a5ff':'#222'}" @click="changeList('03')" >传说典故</span>
        <span :style="{'color':open=='04'?'#00a5ff':'#222'}" @click="changeList('04')" >名人介绍</span>   
        <div :class="['ac_block'+open, ' block' ]"></div> 
    </div> 
    <div class="container-header">
      <p>{{Title}}</p>
    </div>
    <div class="container-img">
      <img :src="info.pic" alt="" srcset="">
    </div>
    <!-- 音频 -->
    <div class="player" v-show="info.video!=''">
　　　　<div  class="centerPlay" v-if="isPlayAudio" @click="playAudio">
　　　　　　<img src="../../static/play.jpg"/>
　　　　</div>
　　　　<div  class="centerPlay" v-else  @click="playAudio">
　　　　　　<img src="../../static/pause.jpg"/>
　　　　</div>
        <div class="play-box">
          <van-slider v-model="audioTime" @change="sliderChange" />
　　　　</div>
　　</div>
    <div class="video">
　　　　<audio :src="info.video" ref="audio" muted @loadeddata="onloadeddata" autoplay></audio>
　　</div>
    <!--  -->
    <div v-html="info.text" class="htmlInfo rich-text"></div>
  
  </div>
</template>

<script>
import { Slider } from 'vant';
export default {
    data () {
        return {
            info:{},
            contentId:'',
            open:'01',
            openId:'',         
            isPlayAudio:false,
            audioSeek:0,
            audioTime:0,
            audioDuration: 0,
        };
    },
    components: {
      [Slider.name]:Slider,
    },
    created() {
        this.openId = this.$route.query.openId;
        this.contentId = this.$route.query.contentId;
        this.getRecords("min-introduce-spot");
    },
    computed: {
      Title:function(){
        if(this.open=='01'){
          return '景点介绍';
        }else if(this.open=='02'){
          return '景点荣耀';
        }else if(this.open=='03'){
          return '传说典故';
        }else if(this.open=='04'){
          return '名人介绍';
        }         
      }
    },
    methods: {
      // 改变bar
      changeList(id) {
          this.open=id;
          this.getRecords(this.getTopicSymbol(id));
      },
      // 获取数据
      getRecords(topicSymbol){
        let postData= {
          topicSymbol: topicSymbol,
        }
        this.$api.getContentByPage(postData).then(res=>{
          this.isPlayAudio = false;
          this.audioSeek=0,
          this.audioTime=0,
          this.audioDuration= 0,
          this.info = this.getIntroduceObj(this.open,res.data.data.records[0].content);   
          clearInterval(this.timer);   
          this.Timer();     
          this.playAudio();   
        })
      },
      // 音频文件加载完成
      onloadeddata(){
        this.audioDuration = this.$refs['audio'].duration;
        console.log(this.audioDuration)
      },
      // 
      getTopicSymbol(open) {
        let symbol = "";
        switch (open) {
          case '01':
            symbol = "min-introduce-spot";
            break;
          case '02':
            symbol = "min-introduce-honor";
            break;
          case '03':
            symbol = "min-introduce-allusion";
            break;
          case '04':
            symbol = "min-introduce-celebrity";
            break;
          default:
            symbol = "min-introduce-spot";
        }
        return symbol;
      },
      getIntroduceObj(open,obj) {
        let data ={};
        switch (open) {
          case '01':
            data.pic = obj.spotPic;
            data.video = obj.spotVideo;
            data.text = obj.spotText;
            break;
          case '02':
            data.pic = obj.honorPic;
            data.video = obj.honorVideo;
            data.text = obj.honorText;
            break;
          case '03':
            data.pic = obj.allusionPic;
            data.video = obj.allusionVideo;
            data.text = obj.allusionText;
            break;
          case '04':
            data.pic = obj.celebrityPic;
            data.video = obj.celebrityVideo;
            data.text = obj.celebrityText;
            break;
          default:
            data.pic = obj.spotPic;
            data.video = obj.spotVideo;
            data.text = obj.spotText;
        }
        return data;
      },
      //拖动进度条事件
      sliderChange(value) {
        console.log(value)
        //获取进度条百分比
        var value = value;
        this.audioTime =value;
        var duration = this.audioDuration;
        //根据进度条百分比及歌曲总时间，计算拖动位置的时间
        value = parseInt(value * duration / 100);
        //更改状态
        this.audioSeek = value;
        //调用seek方法跳转歌曲时间
        console.log(value)
        this.$refs['audio'].currentTime = value;
        //播放歌曲
        this.$refs['audio'].play();
        this.isPlayAudio = true;
      },
      playAudio(){
        //获取播放状态和当前播放时间
        var isPlayAudio = this.isPlayAudio;
        var seek = this.audioSeek;
        this.$refs['audio'].pause();
        //更改播放状态
        this.isPlayAudio =  !isPlayAudio;

        if (isPlayAudio) {
          //如果在播放则记录播放的时间seek，暂停
          this.audioSeek =  this.$refs['audio'].currentTime ;
        } else {
          //如果在暂停，获取播放时间并继续播放
          // this.$refs['audio'].src = this.data.audiosrc;
          if ( this.$refs['audio'].duration != 0) {
            this.audioDuration= this.$refs['audio'].duration
          }
          //跳转到指定时间播放
          this.$refs['audio'].currentTime = seek;
          this.$refs['audio'].play();     
        };    
      },
      Timer(){
        console.log(this.isPlayAudio)
        //设置一个计步器
        this.timer = setInterval(()=> {
          //当歌曲在播放时执行
          if (this.isPlayAudio == true) {
            //获取歌曲的播放时间，进度百分比
            var seek = this.audioSeek;
            var duration = this.$refs['audio'].duration;
            var time = this.audioTime;
            time = parseInt(100 * seek / duration);
            console.log(duration)
            //当进度条完成，停止播放，并重设播放时间和进度条
            if (time >= 100) {
              console.log('12321313')
              this.$refs['audio'].pause();
              this.audioSeek = 0;
              this.audioTime = 0;
              this.audioDuration = duration;
              this.isPlayAudio = false;
              return false;
            }
            //正常播放，更改进度信息，更改播放时间信息
            this.audioSeek = seek + 1;
            this.audioTime = time;
            this.audioDuration = duration;
          };
        }, 1000);
      },

      
    }, 
    // 组件销毁
    beforeDestroy(){
      clearInterval(this.timer);
    } ,
}

</script>
<style scoped>
.List-title {
  width: 10rem;
  height: 1.12rem;
  border-bottom: 1px solid #c7c7c7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: #fff;
}
.List-title span {
  width: 2.5rem;
  height: 1.12rem;
  font-size: 0.4rem;
  color: #222;
  line-height: 1.12rem;
  display: inline-block;
  text-align: center;
}
.active {
  color: #00a5ff;
}

.block {
  position: absolute;
  width:2.5rem;
  height: 2px;
  background: #00a5ff;
  bottom: -0.026rem;
  left: 0;
  transition: all 0.4s;
}

.ac_block02 {
  left: 2.5rem;
}

.ac_block03 {
  left: 5rem;
}

.ac_block04 {
  left: 7.5rem;
}
.container-header{
  margin-top:1.12rem;
  height: 0.586rem;
  padding: 0.426rem;
  position: relative;
}
.container-header p{
  font-size:0.426rem ;
  color: #222222;
  font-weight: bold;
  text-indent: 0.2rem;
  margin: 0;
}
.container-header p::before{
  content: '';
  width: 0.08rem;
  height: 0.4rem;
  border-radius: 0.053rem;
  background: #00A5FF;
  left: 0.32rem;
  top: 0.53rem;
  position: absolute;
}
.container-img{
  width: 10rem;
  height: 5.17rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
}
.container-img img{
  width: 100%;
  height: 100%;
}
.htmlInfo{
  width: 10rem;
  padding: 0 0.32rem;
  margin-top:0.32rem;  
  box-sizing: border-box;
}

.player{
  width: 10rem;
  height: 1rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
}
.player .centerPlay{
  width: 0.426rem;
  height: 0.426rem; 
}
.centerPlay img{
  width: 0.426rem;
  height: 0.426rem; 
  float: left;
  margin-top: 0.24rem;
}
.play-box{
  width: 8.67rem;
  float: right;
}

</style>